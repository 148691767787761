import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import Header from '../../Layout/Header1';
import Footer from '../../Layout/Footer';
import PageTitle from '../../Layout/PageTitle';

// var bg1 = require('images/banner/bnr2.jpg');
import bnr1 from './../../../images/banner/bnr1.jpg';
import bnr2 from './../../../images/background/bg1.png';
import about1 from './../../../images/about/pic1.jpg';
import about2 from './../../../images/about/pic2.jpg';
import about3 from './../../../images/about/pic3.jpg';
import bnr3 from './../../../images/pattern/pic1.jpg';
import work1 from './../../../images/our-work/pic1.jpg';
import work3 from './../../../images/our-work/pic3.jpg';
import work5 from './../../../images/our-work/pic5.jpg';
import bnr4 from './../../../images/background/bg1.jpg';

const imageblog =[
	{image: work1, title:'Understand the situation',},
	{image: work3, title:'Bring the experience to life',},
	{image: work5, title:'Strategise chart the course',},
];
const counterBlog = [
	{icon: <i className="icon flaticon-bar-chart text-primary" />, num: '4922',  title: 'Completed Projects',},	
	{icon: <i className="icon flaticon-avatar text-primary" />, num: '2934',    title: 'Happy Clients',},	
	{icon: <i className="icon flaticon-file text-primary" />, num: '2296',   title: 'Questions Answered',},	
	{icon: <i className="icon flaticon-pen text-primary" />, num: '1343', title: 'Ordered Coffees',},	
];

class Aboutus extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt" style={{ backgroundImage: "url(" + bnr1 + ")" }}>
                        <PageTitle motherMenu='About Us' activeMenu='About Us' />
                    </div>
                    <div className="content-block">
						<div className="section-full bg-white content-inner" style={{ backgroundImage: "url(" + bnr2 + ")" }}>
                            <div className="container">
                                <div className="section-content">
                                    <div className="row">
                                    
                                        <div class="text-black text-center">
                                            <h2 class="box-title">What we do</h2>
                                            <p>To be the most reliable partner in the field of Information and Communication Technology by providing best and cost effective solutions and services to its clients and helping them to improve IT efficiency and business profitability.</p>
                                        </div>

                                        <div className="col-6 col-lg-3 col-md-6 col-sm-6">
                                            <div className="icon-bx-wraper">
                                                <div className="icon-md text-black m-b20">
                                                    <Link to ={"#"} className="icon-cell text-black"><i className="flaticon-trophy"></i></Link>
                                                </div>
                                                <div className="icon-content m-b30">
                                                    <h5 className="dlab-tilte">Trusted Integration Partner</h5>
                                                    <p>Continuously identifying strategic opportunities for developing and delivering solutions</p>
                                                </div>
                                            </div>
                                            <div className="icon-bx-wraper m-b30">
                                                <div className="icon-md text-black m-b20">
                                                    <Link to ={"#"} className="icon-cell text-black"><i className="flaticon-notebook"></i></Link>
                                                </div>
                                                <div className="icon-content">
                                                    <h5 className="dlab-tilte">Managed services plus</h5>
                                                    <p>Way to offload general tasks to an expert, in order to reduce costs, improve service quality</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-lg-3 col-md-6 col-sm-6">
                                            <div className="icon-bx-wraper">
                                                <div className="icon-md text-black m-b20">
                                                    <Link to ={"#"} className="icon-cell text-black"><i className="flaticon-bar-chart"></i></Link>
                                                </div>
                                                <div className="icon-content m-b30">
                                                    <h5 className="dlab-tilte">Maximum Efficiency</h5>
                                                    <p>High-profile projects allow us to deliver smooth, efficient projects consistently.</p>
                                                </div>
                                            </div>
                                            <div className="icon-bx-wraper  m-b30">
                                                <div className="icon-md text-black m-b20">
                                                    <Link to ={"#"} className="icon-cell text-black"><i className="flaticon-team"></i></Link>
                                                </div>
                                                <div className="icon-content">
                                                    <h5 className="dlab-tilte">True Responsiveness</h5>
                                                    <p>Our team of experts are trained to solve all your queries quickly and effeciently.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-lg-3 col-md-6 col-sm-6 m-b30">
                                            <div className="dlab-media dlab-img-overlay6 gradient radius-sm">
                                                <img src={about1} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-6 col-lg-3 col-md-6 col-sm-6" >
                                            <div className="dlab-media dlab-img-overlay6 gradient radius-sm">
                                                <img src={about2} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section-full ">
                            <div className="container-fluid">
                                <div className="row dzseth">
                                    <div className="col-lg-6 col-md-6 col-xs-12 p-a0 img-fix">
                                        <img src={about3} alt="" className="img-cover" />
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-xs-12 bg-black-light p-lr0 dis-tbl">
                                        <div className="p-a30 dis-tbl-cell">
                                            <div className="max-w500 m-auto p-tb30">
                                                <div className="row">
                                                    <div className="col-lg-12 text-white">
                                                        <h2>We provide high quality and cost effective services</h2>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12 m-b30 text-gray">
                                                    <p>Technoa Middle East is regions one of the fast emerging ICT Services Company, based in Dubai, United Arab Emirates. With well-founded track in the field of Information and Communication technology, Technoa delivers ICT infrastructure Support and Services to all SME categories in the Middle East.</p>

                                                    <p>Through a team of professionally qualified industry experts, consultants and strategic business alliances, we provide Technical Support and Consultancy service to the Industry in the areas of ICT Projects, Infrastructure Maintenance and Service, IT Consultancy, IT Audit, Network Infrastructure and many more.</p>

                                                    <p>Our prime objective it to bring technology in to dependable, cost-effective and scalable solutions which supports the customer to be the forefront in their market sector.</p>
                                                     
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section-full p-tb80">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="text-center max-w600 m-auto ">
                                            <div className="m-b20"><i className="fa fa-quote-left font-45 text-primary"></i></div>
                                            <h4 className="text-uppercase font-weight-700 font-30">We would love to hear about start your new project?</h4>
                                            <Link to={"#"} className="site-button radius-xl outline outline-2"><span className="p-lr10">Start New Projects</span></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />

            </>
        )
    }
}
export default Aboutus;