import React,{Fragment,  useState} from 'react';
import {Link} from 'react-router-dom';
import { TabContent, TabPane, } from 'reactstrap';
import classnames from 'classnames';

//Images
import pic5 from './../../images/about/pic5.jpg';
import pic6 from './../../images/about/pic6.jpg';
import pic7 from './../../images/about/pic7.jpg';
import pic8 from './../../images/about/pic8.jpg';

function Para(){
	return(
		<>
			<div className="abuot-box p-lr30">
				<p>To be the most reliable partner in the field of Information and Communication Technology by providing best and cost effective solutions and services to its clients and helping them to improve IT efficiency and business profitability.</p>
				<p>Our prime objective it to bring technology in to dependable, cost-effective and scalable solutions which supports the customer to be the forefront in their market sector.</p>
			</div>	
		</>
	)
}

const HomeTab = (props) => {
	const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
	  
	return(
		<Fragment>			
			<div className="container-fluid">
				<div className="dlab-tabs choseus-tabs">
					<ul className="nav row justify-content-center"  id="myTab" role="tablist">
						{/* <li>
							<a className= {classnames({ active : activeTab === '1' }) + ' className if avialble'}  onClick={() => { toggle('1'); }}	
							><span className="title-head">Cost Effectiveness</span></a>
						</li> */}
						<li>
							<Link to ={"#"} className= {classnames({ active : activeTab === '1'}) + ''} onClick={() => { toggle('1'); }}><span className="title-head">Cost Effectiveness</span></Link>
						</li>
						<li>
							<Link to ={"#"}	className={classnames({active:activeTab === '2'}) + ''} onClick={()=>{ toggle('2');}}><span className="title-head">Flexible Engagement Models</span></Link>
						</li>
						<li>
							<Link to ={"#"}	className={classnames({active:activeTab === '3'}) + ''} onClick={()=>{ toggle('3');}}><span className="title-head">Technology Expertization</span></Link>
						</li>
						<li>
							<Link to ={"#"}	className={classnames({active:activeTab === '4'}) + ''} onClick={()=>{ toggle('4');}}><span className="title-head">Pre-Built Packages</span></Link>
						</li>
					</ul>
				</div>	
			</div>
			<div className="container">
				<div className="tab-content chosesus-content">
					<div id="cost" className="tab-pane active">
						<TabContent activeTab={activeTab}>
							<TabPane tabId="1">
								<div className="row p-tb50">
									<div className="col-lg-6 m-b10 col-md-4 about-img " >
										<img src={pic5} alt="" />
									</div>
									<div className="col-lg-6 col-md-8 text-white">
										<div className="p-l30 " >
											<h4 className="font-weight-300">Project Cost Effectiveness</h4>
											<ul className="list-checked primary">
												<li><span>The Best and Cost Effective Solutions to meet your ACTUAL REQUIREMENT</span></li>
												<li><span>Identify the availability and reliability of the data</span></li>
												<li><span>Determine the effectiveness criteria and develop the relevant indicator</span></li>
											</ul>
										</div>
										<Para />
									</div>
								</div>
							</TabPane>
							<TabPane tabId="2">
								<div className="row p-tb50">
									<div className="col-lg-6 col-md-4 about-img">
										<img src={pic6} alt="" />
									</div>
									<div className="col-lg-6 col-md-8 text-white">
										<div className="p-l30">
											<h4 className="font-weight-300">Flexible Engagement Models</h4>
											<ul className="list-checked primary">
												<li><span>We manage the complete life cycle of the project and are responsible for deliverables & quality</span></li>
												<li><span>In the Effort based pricing model, the project cost is a function of the total project execution time and the corresponding resources deployed</span></li>
												<li><span>Project life cycle distributed between onsite and offshore teams</span></li>
											</ul>
										</div>
										<Para />
									</div>
								</div>
							</TabPane>
							<TabPane tabId="3">
								<div className="row p-tb50">
									<div className="col-lg-6 col-md-4 about-img">
										<img src={pic7} alt="" />
									</div>
									<div className="col-lg-6 col-md-8 text-white">
										<div className="p-l30">
											<h4 className="font-weight-300">Technology Expertization</h4>
											<ul className="list-checked primary">
												<li><span>Leverage our open source expertise to ensure the success of your projects</span></li>
												<li><span>Virtual extension of the customer’s operating environment</span></li>
												<li><span>We support our customers in choosing the right development environment across Native, Cross Platform and Hybrid approaches.</span></li>
											</ul>
										</div>
										<Para />
									</div>
								</div>
							</TabPane>
							<TabPane tabId="4">
								<div className="row p-tb50">
									<div className="col-lg-6 col-md-4 about-img">
										<img src={pic8} alt="" />
									</div>
									<div className="col-lg-6 col-md-8 text-white">
										<div className="p-l30">
											<h4 className="font-weight-300">Pre-Built Packages</h4>
											<ul className="list-checked primary">
												<li><span>Supporting you through the entire project lifecycle</span></li>
												<li><span>Customer-specific Agile Development</span></li>
												<li><span>Our delivery model is optimized to create a high performing team, bringing together the strengths of your own employees</span></li>
											</ul>
										</div>
										<Para />
									</div>
								</div>
							</TabPane>
							
						</TabContent>
					</div>	
				</div>
			</div>	
		</Fragment>
	)
	
}	
export default HomeTab;