import React,{Component} from 'react';
import Header from './../Layout/Header1';
import Footer from '../Layout/Footer';
import HomeOwlSlider from './../Element/HomeOwlSlider';
import ImgCarouselContent from './../Element/ImgCarouselContent';
import HomeTab from './../Element/HomeTab';
import Counter from './../Element/Counter';
import ServiceBox from './../Element/ServiceBox';

import ClientCarousel from './../Element/ClientCarousel';
import { Link } from 'react-router-dom';
//Images
import pic4 from './../../images/about/pic4.jpg';
import bgimg from './../../images/background/bg1.jpg';
// import bgimg2 from './../../images/background/bg-map.jpg';
// import bgimg3 from './../../images/background/bg1.jpg';

class Homepage extends Component{
		
	componentDidMount() {
		
	}	
	
	render(){
		return(
			<div className="page-wraper">
				<Header />
				<div className="page-content bg-white">
					{/*  Slider Banner */}
					<div className="owl-slider-banner main-slider">
						<HomeOwlSlider />
						{/*  Service   */}
						<div className="service-box-slide">
							<div className="container">
								<div className="row">
									<div className="col-lg-12">
										<ImgCarouselContent />
									</div>
								</div>
							</div>
						</div>
						{/*  Service End  */}
					</div>
					{/*  Slider Banner */}
					<div className="content-block">
                        {/* <!-- About Us --> */}
                        <div className="section-full bg-white content-inner-1 about-us">
                            <div className="container">
                                <div className="row ">
                                    <div className="col-lg-7 col-md-8">
                                        <div className="abuot-box row" >
                                            <div className="col-lg-4">
                                                <h2 className="box-title m-tb0">About Us<span className="bg-primary"></span></h2>
                                                <h4 className="text-gray-dark">We create unique experiences</h4>
                                            </div>
                                            <div className="col-lg-8">
                                                <p>Technoa Middle East is regions one of the fast emerging ICT Services Company, based in Dubai, United Arab Emirates. With well-founded track in the field of Information and Communication technology, Technoa delivers ICT infrastructure Support and Services to all SME categories in the Middle East.</p>
                                                <p>Our prime objective it to bring technology in to dependable, cost-effective and scalable solutions which supports the customer to be the forefront in their market sector.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-4 about-img" >
                                        <img src={pic4} data-tilt alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- About Us End --> */}
                         {/* <!-- Our Services --> */}

						 
						 <div className="section-full content-inner bg-white">
                        <div className="container">
                            <div className="max-w800 m-auto text-center">
                            <h2 className="box-title m-tb10">Our Services<span className="bg-primary"></span></h2>
                                <p>We blend engineering, innovation and design with a unique approach to enable business reinvention. Everything from creating standards of excellence to training your people to work in more effective ways, assessing how you’re doing, and helping you perform even better in future. 
                            </p>
                            </div>
                            
							<ServiceBox />
                        </div>
                    </div>
                    
                    	{/* <!-- Our Services End--> */}
						
						{/* <!-- Why Chose Us --> */}
                        <div className="section-full content-inner-1 overlay-primary choseus-tabs  bg-img-fix" style={{ backgroundImage: "url(" + bgimg + ")" }}>
                            <div className="container">
                                <div className="section-head text-white text-center">
                                    <h2 className="box-title m-tb0">Why Chose Us<span className="bg-primary"></span></h2>
                                    <p>We blend engineering, innovation and design with a unique approach to enable business reinvention. </p>
                                </div>
                            </div>
							<HomeTab />
							<div className="choses-info text-white">
                                <Counter />
                            </div>
						</div>	
						{/* <!-- Why Chose Us End --> */}
						
                        <div className="section-full p-tb80">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="text-center max-w600 m-auto ">
                                            <div className="m-b20"><i className="fa fa-quote-left font-45 text-primary"></i></div>
                                            <h4 className="text-uppercase font-weight-700 font-30">We would love to hear about start your new project?</h4>
                                            <Link to={"#"} className="site-button radius-xl outline outline-2"><span className="p-lr10">Start New Projects</span></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="section-head text-center">
                                <h2 className="box-title m-tb0">We've worked with<span className="bg-primary"></span></h2>
                            </div>
							<ClientCarousel /> 
                        </div>
                    </div>
                    {/* <!-- Partners Logo --> */}
						
					
				</div>	
				<Footer />
				
			</div>		
		)
	}
}
export default Homepage;