import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from './../../Layout/Header1';
import Footer from './../../Layout/Footer';
import PageTitle from './../../Layout/PageTitle';
import ServicesModule from './../Service/ServicesModule';
import ServiceBox from './../../Element/ServiceBox';


//Images
import bnr1 from './../../../images/banner/bnr4.jpg';


class ServicesDetails extends Component {

    render() {
        return (
            <>
                <Header />

                <div className="page-content bg-white">
                    {/* <!-- inner page banner --> */}
                    <div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr1 + ")" }}>
                        <PageTitle motherMenu='Our Services' activeMenu='Our Services' />
                    </div>
                    {/* <!-- inner page banner END --> */}
                    <div className="content-block">
                        {/* <!-- About Us --> */}
                        <div className="section-full content-inner exp-services">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-head text-black text-center">
                                            <h2 className="box-title m-tb0">Scalable, interactive & efficient solutions on your way<span className="bg-primary"></span></h2>
                                            <p className="font-weight-300 font-18">We blend engineering, innovation and design with a unique approach to enable business reinvention. Everything from creating standards of excellence to training your people to work in more effective ways, assessing how you’re doing, and helping you perform even better in future.</p>
                                        </div>
                                        {/* <ServiceBox /> */}
                                        <div className="row">
                                            <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
                                                <a className='smoothscroll' href={'#webdevelopment'}>
                                                    <div className="icon-bx-wraper bx-style-1 p-a30 center fly-box-ho">
                                                        <div className="icon-sm m-b20"><i className="ti-desktop" /></div>
                                                        <div className="icon-content">
                                                            <h6>Web Design & Development</h6>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
                                                <a className='smoothscroll' href={'#ictservice'}>
                                                    <div className="icon-bx-wraper bx-style-1 p-a30 center fly-box-ho">
                                                        <div className="icon-sm m-b20"><i className="ti-headphone-alt" /></div>
                                                        <div className="icon-content">
                                                            <h6>ICT Project Managenent Division</h6>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                           

                                            <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
                                                <a className='smoothscroll' href={'#networkinfra'}>
                                                    <div className="icon-bx-wraper bx-style-1 p-a30 center fly-box-ho">
                                                        <div className="icon-sm m-b20"> <i className="ti-world" /> </div>
                                                        <div className="icon-content">
                                                            <h6>Network <br/>Infrastructure</h6>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
                                                <a className='smoothscroll' href={'#ismsc'}>
                                                    <div className="icon-bx-wraper bx-style-1 p-a30 center fly-box-ho">
                                                        <div className="icon-sm m-b20"><i className="ti-dashboard" /> </div>
                                                        <div className="icon-content">
                                                            <h6>Maintenance and Service Contracts</h6>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
                                                <a className='smoothscroll' href={'#itconsultancy'}>
                                                    <div className="icon-bx-wraper bx-style-1 p-a30 center fly-box-ho">
                                                        <div className="icon-sm m-b20"> <i className="flaticon-management" /> </div>
                                                        <div className="icon-content">
                                                            <h6>IT Consultancy</h6>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
                                                <a className='smoothscroll' href={'#outsourceit'}>
                                                    <div className="icon-bx-wraper bx-style-1 p-a30 center fly-box-ho">
                                                        <div className="icon-sm m-b20"><i className="flaticon-business" /></div>
                                                        <div className="icon-content">
                                                            <h6>Outsourced IT</h6>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
                                                <a className='smoothscroll' href={'#itauditing'}>
                                                    <div className="icon-bx-wraper bx-style-1 p-a30 center fly-box-ho">
                                                        <div className="icon-sm m-b20"><i className="flaticon-analytics" /></div>
                                                        <div className="icon-content">
                                                            <h6>IT Auditing</h6>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                            <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
                                                <a className='smoothscroll' href={'#otherservices'}>
                                                    <div className="icon-bx-wraper bx-style-1 p-a30 center fly-box-ho">
                                                        <div className="icon-sm m-b20"><i className="flaticon-creative" /></div>
                                                        <div className="icon-content">
                                                            <h6>Other Services</h6>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Our Services --> */}

                        <ServicesModule />

                        {/* <!--Our Services End --> */}
                    </div>
                    {/* <!-- contact area END --> */}
                </div>

                <Footer />
            </>
        )
    }
}
export default ServicesDetails;