import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from './../../images/logo.png';

class Footer extends Component {
    render() {
        return (
            <>
                <footer className="site-footer">
					<div className="footer-top bg-gray-dark">
						<div className="container">
							<div className="row">
								<div className="col-5 col-lg-2 col-md-6 col-sm-6 footer-col-4">
									<div className="widget widget_services border-0">
										<h5 className="m-b30 text-white">Company</h5>
										<ul>
											<li><Link to={'./about-us'}>About Us</Link></li>
											<li><Link to={'./services-details'}>Services</Link></li>
											<li><Link to={'./our-projects'}>Projects </Link></li>
											<li><Link to={'./contact'}>Contact Us</Link></li>
										</ul>
									</div>
								</div>
								<div className="col-7 col-lg-3 col-md-6 col-sm-6 footer-col-4">
									<div className="widget widget_services border-0">
										<h5 className="m-b30 text-white">Useful Link</h5>
										<ul>
											<li><Link to={'./services-details'}>ICT Projects Division</Link></li>
											<li><Link to={'./services-details'}>IT Consultancy</Link></li>
											<li><Link to={'./services-details'}>Network Infrastructure</Link></li>
											<li><Link to={'./services-details'}>Web Design & Development</Link></li>
										</ul>
									</div>
								</div>
								<div className="col-lg-3 col-md-6 col-sm-6 footer-col-4">
									<div className="widget widget_getintuch">
										<h5 className="m-b30 text-white">Contact us</h5>
										<ul>
											<li><i className="ti-location-pin"></i><strong>address</strong> 307A, Al Nakheel Building, Karama, Dubai,<br/> UAE. PB No: 252435 </li>
											<li><i className="ti-mobile"></i><strong>phone</strong>+971 4 3885533</li>
										</ul>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 col-sm-6 footer-col-4 ">
									<div className="widget">
										<h5 className="m-b30 text-white">Who we are</h5>
										<p className="text-capitalize m-b20">Technoa Middle East is regions one of the fast emerging ICT Services Company, based in Dubai, United Arab Emirates.</p>
										<img width="240px" src={logo} alt="" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="footer-bottom bg-primary">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 text-left "> <span>Copyright © 2022 Technoa Middle East Technology LLC.</span> </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 text-right ">
                                    <div className="widget-link ">
                                        <ul>
                                            {/* <li><Link to={"#"}> Help Desk</Link></li> */}
                                            <li className="ml-1"><Link to={"#"}> Privacy Policy</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
				</footer>
	
            </>
        );
    }
}

export default Footer;