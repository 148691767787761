import React, {Fragment, Component } from 'react';
//import { Link } from 'react-router-dom';

//Images
import ictimg from './../../../images/our-services/ictimage.jpg';
import maintenanceimg from './../../../images/our-services/maintenanceimg.jpg';
import outsourcedimg from './../../../images/our-services/outsourcedimg.jpg';
import itconsultancyimg from './../../../images/our-services/itconsultancyimg.jpg';
import networkimg from './../../../images/our-services/networkimg.jpg';
import auditingimg from './../../../images/our-services/auditingimg.jpg';
import webdevelopmentimg from './../../../images/our-services/webdevelopmentimg.jpg';
import otherservicesimg from './../../../images/our-services/otherservicesimg.jpg';


class ServiceModule extends Component{
	render(){
		return(
			<Fragment>
                {/* <!-- ICT Service --> */}
                        <div className="section-full" id='ictservice'>
                            <div className="container-fluid">
                                <div className="row dzseth">
                                    <div className="col-lg-6 col-md-12 p-a0 img-fix">
                                        <img src={ictimg} alt="" className="img-cover" />
                                    </div>
                                    <div className="col-lg-6 col-md-12 bg-black-light p-lr0 dis-tbl">
                                        <div className="p-a30 dis-tbl-cell ">
                                            <div className="max-w500 m-auto p-tb30">
                                                <div className="row">
                                                    <div className="col-lg-12 text-white">
                                                        <h3 className="box-title m-b10">ICT Projects Division<span className="bg-primary"></span></h3>
                                                        <h5 className="m-b30">Single Contact for all your ICT Project Requirements</h5>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12 m-b30 chosesus-content">
                                                    <h4 className="text-white m-b10">Areas of Expertise</h4>
                                                        <ul className="list-checked primary text-white">
                                                            <li><span>Data Center Designing and Implementation</span></li>
                                                            <li><span>Server Installation and Migration</span></li>
                                                            <li><span>Email, Real-Time and Unified Communication</span></li>
                                                            <li><span>Network and Information Security</span></li>
                                                            <li><span>Wide Area Networks (WAN) Designing and Implementation</span></li>
                                                            <li><span>Hospitality Segment Projects</span></li>
                                                            <li><span>Cloud Computing</span></li>
                                                        </ul>
                                                        <p>Implementation of New / Up-gradation of existing ICT Infrastructure.</p>
                                                        <p>We offer professional services which ranges from simple workgroup environment to multi-platform IT infrastructure projects including requirement study, cost implication, project planning, designing, procurement, execution, testing and commissioning.</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                {/* <!--ICT Service End --> */}
                {/* <!-- IS MSC Contracts --> */}
                <div className="section-full" id='ismsc'>
                            <div className="container-fluid">
                                <div className="row dzseth">
                                    
                                    <div className="col-lg-6 col-md-12 bg-white-light p-lr0 dis-tbl">
                                        <div className="p-a30 dis-tbl-cell ">
                                            <div className="max-w500 m-auto p-tb30">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <h3 className="box-title m-b10">IS MSC Contracts<span className="bg-primary"></span></h3>
                                                        <h5 className="m-b30">Complete Pease of Mind on Your MISSION CRITICAL IT Infrastructure</h5>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12 m-b30 chosesus-content">
                                                    <p>ICT Infrastructure and its uninterrupted availability is a major concern in all modern business environments. Through a team of technically qualified and professional trained engineers, Technoa offers Information System Maintenance Services Contracts (IS MSC) ranging from an hour to unlimited hours or visits for a year or more.</p>
                                                        <p>Through the various packages offered, clients can avail our professional services with very minimal response time and the flexibility to demand the services round the clock throughout the year.</p>
                                                    <h4 className="m-b10">Contract Categories</h4>
                                                        <ul className="list-checked primary ">
                                                            <li><h6>Platinum Level</h6>
                                                            <p>Exclusive Service Agreements with bundle of benefits and top most priority where the ICT infrastructure in mission critical.</p></li>
                                                            <li><h6>Gold Level</h6>
                                                            <p>Standard Service Agreements with higher priority with an average response time.</p></li>
                                                            <li><h6>Silver Level</h6>
                                                            <p>Service Agreements with medium / minimal priority aiming the non-critical ICT environment.</p></li>
                                                            <li><h6>Bronze Level</h6>
                                                            <p>On demand professional services without necessity of a contract.</p></li>
                                                            
                                                        </ul>
                                                 
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-12 p-a0 img-fix">
                                        <img src={maintenanceimg} alt="" className="img-cover" />
                                    </div>
                                </div>
                            </div>
                        </div>
                {/* <!--IS MSC Contracts End --> */}

                {/* <!-- Outsourced IT --> */}
                <div className="section-full" id='outsourceit'>
                            <div className="container-fluid">
                                <div className="row dzseth">
                                    <div className="col-lg-6 col-md-12 p-a0 img-fix">
                                        <img src={outsourcedimg} alt="" className="img-cover" />
                                    </div>
                                    <div className="col-lg-6 col-md-12 bg-black-light p-lr0 dis-tbl">
                                        <div className="p-a30 dis-tbl-cell ">
                                            <div className="max-w500 m-auto p-tb30">
                                                <div className="row">
                                                    <div className="col-lg-12 text-white">
                                                        <h3 className="box-title m-b10">Outsourced IT<span className="bg-primary"></span></h3>
                                                        <h5 className="m-b30">Technoa works like YOUR OWN IT DEPARTMENT with complete flexibility</h5>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12 m-b30 chosesus-content">
                                                        <p>We offer flexible and wide range of IT outsourcing packages to meet all the demands of IT Outsourcing which ranges from limited hours to 24/7/365 in-house resident engineers.</p>
                                                    <h4 className="text-white m-b10">Services Offered</h4>
                                                        <ul className="list-checked primary text-white">
                                                            <li><span>Full Time / Part Time Resident Engineers</span></li>
                                                            <li><span>Managed Services</span></li>
                                              
                                                        </ul>
                                               
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                {/* <!-- Outsourced IT End --> */}

                 {/* <!-- IT Consultancy --> */}
                 <div className="section-full" id='itconsultancy'>
                            <div className="container-fluid">
                                <div className="row dzseth">
                                    
                                    <div className="col-lg-6 col-md-12 bg-white-light p-lr0 dis-tbl">
                                        <div className="p-a30 dis-tbl-cell ">
                                            <div className="max-w500 m-auto p-tb30">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <h3 className="box-title m-b10">IT Consultancy<span className="bg-primary"></span></h3>
                                                        <h5 className="m-b30">The Best and Cost Effective Solutions to meet your ACTUAL REQUIREMENT</h5>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12 m-b30 chosesus-content">
                                                        <p>Technoa provides consultancy service to our clients to assess the security and cost effectiveness of their IT systems and help them design, integrate and modernize their mission-critical infrastructure to achieve their business goals.</p>
                                                    <h4 className="m-b10">Professional Consultancy Services Offered in</h4>
                                                        <ul className="list-checked primary ">
                                                            <li><span>Communication Systems</span></li>
                                                            <li><span>Surveillance Systems</span></li>
                                                            <li><span>Access Control, Time and Attendance System</span></li>
                                                        </ul>
                                                       
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-12 p-a0 img-fix">
                                        <img src={itconsultancyimg} alt="" className="img-cover" />
                                    </div>
                                </div>
                            </div>
                        </div>
                {/* <!-- IT Consultancy End --> */}

                 {/* <!-- Network Infrastructure --> */}
                 <div className="section-full" id='networkinfra'>
                            <div className="container-fluid">
                                <div className="row dzseth">
                                    <div className="col-lg-6 col-md-12 p-a0 img-fix">
                                        <img src={networkimg} alt="" className="img-cover" />
                                    </div>
                                    <div className="col-lg-6 col-md-12 bg-black-light p-lr0 dis-tbl">
                                        <div className="p-a30 dis-tbl-cell ">
                                            <div className="max-w500 m-auto p-tb30">
                                                <div className="row">
                                                    <div className="col-lg-12 text-white">
                                                        <h3 className="box-title m-b10">Network Infrastructure<span className="bg-primary"></span></h3>
                                                        <h5 className="m-b30">STRUCTURED LV NETWORKS</h5>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12 m-b30 chosesus-content ">
                                                        <p>Network infrastructure is one of the core elements for every Data, Voice and Multimedia Network. This provides the communication path and services between users, processes, applications, services and external networks / the Internet.</p>

                                                        <p>Network infrastructure is typically part of the IT Infrastructure for most of the organizations. The entire network infrastructure is interconnected, and can be used for internal communications, external communications or both. A typical network infrastructure includes:</p>

                                                        <p>Networking Hardware: Routers, Switches, Wireless Routers, Cables and other passive components etc.</p>

                                                        <p>Networking Software: Network operations and management, operating systems, a firewall and network security applications</p>

                                                        <p>Network Services: T-1 Line, DSL, satellite, wireless, protocols, IP addressing, etc.</p>

                                                        <p>Technoa provides the complete solution starting from Feasibility Study, Site audit, Network design, Active and Passive components procurement and implementations of structured cabling system that compromises with international and regional standards and regulations.</p>
                                                    <h4 className="text-white m-b10">Services Offered</h4>
                                                        <ul className="list-checked primary text-white">
                                                            <li><span>Structured LAN Implementations through Fiber, Copper and Wireless</span></li>
                                                            <li><span>Structured Cabling for Data, Voice and Multimedia</span></li>
                                                            <li><span>Enterprise Class Indoor, Outdoor and Industrial Wireless Solutions</span></li>
                                              
                                                        </ul>
                                               
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                {/* <!-- Network Infrastructure End --> */}

                 {/* <!-- IT Auditing --> */}
                 <div className="section-full" id='itauditing'>
                            <div className="container-fluid">
                                <div className="row dzseth">
                                    
                                    <div className="col-lg-6 col-md-12 bg-white-light p-lr0 dis-tbl">
                                        <div className="p-a30 dis-tbl-cell ">
                                            <div className="max-w500 m-auto p-tb30">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <h3 className="box-title m-b10">IT Auditing<span className="bg-primary"></span></h3>
                                                        <h5 className="m-b30">To REVEAL THE HIDDEN AREAS of your IT</h5>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12 m-b30 chosesus-content">
                                                        <p>Technoa provides an in-depth evaluation of your existing IT Infrastructure that determines if the Information Systems are safe guarding assets, maintaining data integrity and operating effectively to achieve the organization’s goals and objectives. This also includes the assessment of security protocols, IT governance oversight etc.</p>
                                                    <h4 className="m-b10">Services Offered</h4>
                                                        <ul className="list-checked primary ">
                                                            <li><span>IT Security Audit</span></li>
                                                            <li><span>IT Governance Audit</span></li>
                                                        </ul>
                                                       
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-12 p-a0 img-fix">
                                        <img src={auditingimg} alt="" className="img-cover" />
                                    </div>
                                </div>
                            </div>
                        </div>
                {/* <!-- IT Auditing End --> */}

                {/* <!-- Website Design & Development --> */}
                <div className="section-full" id='webdevelopment'>
                            <div className="container-fluid">
                                <div className="row dzseth">
                                    <div className="col-lg-6 col-md-12 p-a0 img-fix">
                                        <img src={webdevelopmentimg} alt="" className="img-cover" />
                                    </div>
                                    <div className="col-lg-6 col-md-12 bg-black-light p-lr0 dis-tbl">
                                        <div className="p-a30 dis-tbl-cell ">
                                            <div className="max-w500 m-auto p-tb30">
                                                <div className="row">
                                                    <div className="col-lg-12 text-white">
                                                        <h3 className="box-title m-b10">Website Design & Development<span className="bg-primary"></span></h3>
                                                        <h5 className="m-b30">The creativity and flexibility of website designs gives our applications extra boost up.</h5>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12 m-b30 chosesus-content ">
                                                        <p>Our Web application Development includes custom web app development, job portal web app development and web apps for property businesses, E-commerce and other solutions for various industries. Our top-notch back-end development integrated with an eye-appealing front-end, streamlines the user experience to maximise your impact. Our greatest web development services make us stand call at the market.</p>
                                                    <h4 className="text-white m-b10">Areas of Expertise</h4>
                                                        <ul className="list-checked primary text-white">
                                                            <li><span>Enterprise Web App Development Service</span></li>
                                                            <li><span>Mobile Applications</span></li>
                                                            <li><span>Business Consulting</span></li>
                                                            <li><span>Custom Web Development</span></li>
                                                            <li><span>Content Management Applications</span></li>
                                                            <li><span>Hosting Service & Support</span></li>
                                              
                                                        </ul>
                                               
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                {/* <!-- Website Design & Development End --> */}

                 {/* <!-- Other Services --> */}
                 <div className="section-full" id='otherservices'>
                            <div className="container-fluid">
                                <div className="row dzseth">
                                    
                                    <div className="col-lg-6 col-md-12 bg-white-light p-lr0 dis-tbl">
                                        <div className="p-a30 dis-tbl-cell ">
                                            <div className="max-w500 m-auto p-tb30">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <h3 className="box-title m-b10">Other Services<span className="bg-primary"></span></h3>
                                                        <h5 className="m-b30">To REVEAL THE HIDDEN AREAS of your IT</h5>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12 m-b30 chosesus-content">
                                                        <p>To complement our support services and provide our customers with a complete IT solution we have developed an extensive range of services allowing us to deliver to our customers all aspects of Information and Communication Technology.</p>
                                                    <h4 className="m-b10">Technoa Professional ICT Services has an extensive coverage in
the areas of</h4>
                                                        <ul className="list-checked primary ">
                                                            <li><span>Technical Coordination with ISPs for service related matters.</span></li>
                                                            <li><span>Coordination and first level support for third party applications.</span></li>
                                                            <li><span>Data Recovery Services for Servers, NAS, Desktops, Laptops, And Removable Media</span></li>
                                                            <li><span>Technical Consultation for CCTV (Analog / IP) Surveillances.</span></li>
                                                            <li><span>Technical Consultation for Access Control, Time and Attendance Solutions</span></li>
                                                            <li><span>Technical Consultation for IP / Hybrid / Analog based Communication Solutions</span></li>
                                                        </ul>
                                                       
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-12 p-a0 img-fix">
                                        <img src={otherservicesimg} alt="" className="img-cover" />
                                    </div>
                                </div>
                            </div>
                        </div>
                {/* <!-- Other Services End --> */}

            </Fragment>
		)
	}
}
export default ServiceModule;