import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';

class ServiceBox extends Component {
	render() {
		return (
			<Fragment>
				<div className="row">
					<div className="col-lg-4 col-md-6 col-sm-6 m-b30">
						<div className="icon-bx-wraper bx-style-1 p-a30 center fly-box-ho">
							<div className="icon-sm m-b20"> <Link to={"#"} className="icon-cell"><i className="flaticon-devices" /></Link> </div>
							<div className="icon-content">
								{/* <h5 className="dlab-tilte text-uppercase">{item.title}</h5> */}
								<h5>Web Design & Development</h5>
								<p className="m-b0">We turn your website into an effective way to engage with your audience and a high-performing marketing tool for your business.</p>
							</div>
						</div>
					</div>

					<div className="col-lg-4 col-md-6 col-sm-6 m-b30">
						<div className="icon-bx-wraper bx-style-1 p-a30 center fly-box-ho">
							<div className="icon-sm m-b20"> <Link to={"#"} className="icon-cell"><i className="ti-headphone-alt" /></Link> </div>
							<div className="icon-content">
								{/* <h5 className="dlab-tilte text-uppercase">{item.title}</h5> */}
								<h5>ICT Projects Division</h5>
								<p className="m-b0">Single Contact for all your ICT Project Requirements. Implementation of New or Up-gradation of existing ICT Infrastructure.</p>
							</div>
						</div>
					</div>

					<div className="col-lg-4 col-md-6 col-sm-6 m-b30">
						<div className="icon-bx-wraper bx-style-1 p-a30 center fly-box-ho">
							<div className="icon-sm m-b20"> <Link to={"#"} className="icon-cell"><i className="flaticon-bar-chart" /></Link> </div>
							<div className="icon-content">
								{/* <h5 className="dlab-tilte text-uppercase">{item.title}</h5> */}
								<h5>IT Consultancy</h5>
								<p className="m-b0">Technoa provides consultancy service to our clients to assess the security and cost effectiveness of their IT systems and help them design</p>
							</div>
						</div>
					</div>

					<div className="col-lg-4 col-md-6 col-sm-6 m-b30">
						<div className="icon-bx-wraper bx-style-1 p-a30 center fly-box-ho">
							<div className="icon-sm m-b20"> <Link to={"#"} className="icon-cell"><i className="ti-world" /></Link> </div>
							<div className="icon-content">
								{/* <h5 className="dlab-tilte text-uppercase">{item.title}</h5> */}
								<h5>Network Infrastructure</h5>
								<p className="m-b0">This is one of the core elements for every Data, Voice and Multimedia Network. It provides the communication path and services between users, processes, and services.</p>
							</div>
						</div>
					</div>

					<div className="col-lg-4 col-md-6 col-sm-6 m-b30">
						<div className="icon-bx-wraper bx-style-1 p-a30 center fly-box-ho">
							<div className="icon-sm m-b20"> <Link to={"#"} className="icon-cell"><i className="flaticon-team" /></Link> </div>
							<div className="icon-content">
								{/* <h5 className="dlab-tilte text-uppercase">{item.title}</h5> */}
								<h5>Maintenance and Service Contracts</h5>
								<p className="m-b0">Technoa provides consultancy service to our clients to assess the security and cost effectiveness of their IT systems and help them design.</p>
							</div>
						</div>
					</div>

					<div className="col-lg-4 col-md-6 col-sm-6 m-b30">
						<div className="icon-bx-wraper bx-style-1 p-a30 center fly-box-ho">
							<div className="icon-sm m-b20"> <Link to={"#"} className="icon-cell"><i className="flaticon-pen" /></Link> </div>
							<div className="icon-content">
								{/* <h5 className="dlab-tilte text-uppercase">{item.title}</h5> */}
								<h5>Outsourced IT</h5>
								<p className="m-b0">We offer flexible and wide range of IT outsourcing packages to meet all the demands of IT Outsourcing which ranges from limited hours to 24/7/365 in-house resident engineers.</p>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}
export default ServiceBox;